import { getHeader } from "@/utils/headers";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define a service using a base URL and expected endpoints
export const loginApi = createApi({
  reducerPath: "loginApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_BACKEND_API_URL + "user/",
    prepareHeaders: (headers, { getState }) => getHeader(headers, getState),
  }),

  endpoints: (builder) => ({

    completeMobileRegistration: builder.mutation({
      query: (body) => {
        return {
          url: "complete-registration",
          method: "post",
          body: body,

        };
      },
    }),
    userLogin: builder.mutation({
      query: (body) => {
        return {
          url: "login",
          method: "post",
          body: body,
        };
      },
    }),
    userRegister: builder.mutation({
      query: (body) => {
        return {
          url: "register",
          method: "post",
          body: body,
        };
      },
    }),

    getCurrentUser: builder.mutation({
      query: (body) => {
        return {
          url: "current-user",
          method: "post",
          body: body,
        };
      },
    }),
  }),
});

export const {
  useCompleteMobileRegistrationMutation,
  useUserLoginMutation,
  useUserRegisterMutation,
  useGetCurrentUserMutation
} = loginApi;
