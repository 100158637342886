import { getHeader } from "@/utils/headers";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IShipAnyWhereOrder } from "./OrderInterface";

// Define a service using a base URL and expected endpoints
export const ordersApi = createApi({
    reducerPath: "orders",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.NEXT_PUBLIC_BACKEND_API_URL,
        prepareHeaders: (headers, { getState }) => { return getHeader(headers, getState) },
    }),
    tagTypes: ['SHIPPING_ORDER_DETAILS', "SHIPPING_ORDER"],
    endpoints: (builder) => ({
        getShippingOrders: builder.query<any, any>({
            query: (body) => {
                return {
                    url: "shipping/orders",
                    method: "post",
                    body: body,
                };
            },
            providesTags: ['SHIPPING_ORDER']
        }),
        getOrderDetailsByTrackingNumber: builder.query<{ data: IShipAnyWhereOrder }, string>({
            query: (trackingNumber: string) => `shipping/orders-details/${trackingNumber}`,
            providesTags: ['SHIPPING_ORDER_DETAILS'],
        }),
        getShoppingOrderDetailsByTrackingNumber: builder.query<{ data: any }, string>({
            query: (trackingNumber: string) => `shopping/order-details/${trackingNumber}`,
        }),
        getShippingPaymentDetailsByTrackingNumber: builder.query<{ data: any }, string>({
            query: (trackingNumber: string) => `customer/payment-details/${trackingNumber}`,
        }),
        getShoppingOrders: builder.mutation({
            query: (body) => {
                return {
                    url: "shopping/order-list",
                    method: "post",
                    body: body,
                };
            },
        }),
        getTrackingInfo: builder.mutation({
            query: (body) => {
                return {
                    url: "tracking",
                    method: "post",
                    body: body,
                };
            },
        }),
        cacncelShippingOrder: builder.mutation({
            query: (body) => {
                return {
                    url: "shipping/cancel-order",
                    method: "post",
                    body: body,
                };
            },
            invalidatesTags: ['SHIPPING_ORDER_DETAILS', 'SHIPPING_ORDER']
        }),
        getDuplicateOrderPrice: builder.mutation({
            query: (body) => {
                return {
                    url: "shipping/get-duplicate-order-price",
                    method: "post",
                    body: body,
                };
            },
        }),
        //create duplicate order
        createDuplicateOrder: builder.mutation({
            query: (body) => {
                return {
                    url: "shipping/create-duplicate-order",
                    method: "post",
                    body: body,
                };
            },
        }),
        
    }),
});


export const {
    useGetShippingOrdersQuery,
    useGetOrderDetailsByTrackingNumberQuery,
    useGetShippingPaymentDetailsByTrackingNumberQuery,
    useGetShoppingOrdersMutation,
    useGetShoppingOrderDetailsByTrackingNumberQuery,
    useGetTrackingInfoMutation,
    useCacncelShippingOrderMutation,
    useGetDuplicateOrderPriceMutation,
    useCreateDuplicateOrderMutation,
} = ordersApi;
