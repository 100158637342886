export interface BuyProductsLinks {
  link_id: any;
  link: string;
  quantity?: string,
  description?: string,
  title?: string,
  images?: any,
  price?: string,
  status: "pending" | "processing" | "completed"
}
export type ShoppingInputs = {
  link: string
  title: string
  description: string
  price: string
  quantity: string
  coupon_code: string
}
interface ShippingDeliveryInfo {
  sender_country: string;
  receiver_country: string;
  sender_country_name: string;
  receiver_country_name: string;
  receiverWarehouse: null | string | any;
  selectedDeliveryMethod: any;
  selectedWarehouse: null | string | any;
  selectedHomeDeliveryAddress: any;
  selectedCourier: null | string | any;
}
interface OrderSummary {
  total_price: number;
  sales_tax: number;
  service_charge?: number;
}
export interface Ishopping {
  data: {
    buyProductsLinksInfo: BuyProductsLinks[];
    deliveryInformation?: ShippingDeliveryInfo,
    orderSummery: OrderSummary
  };
}
export const initialState: Ishopping = {
  data: {
    buyProductsLinksInfo: [
      {
        link_id: 0,
        link: "",
        description: '',
        title: '',
        images: [],
        price: "0",
        status: "pending",
        quantity: '0'
      },
    ],
    deliveryInformation: {
      sender_country: "US",
      receiver_country: "BD",
      sender_country_name: "United States",
      receiver_country_name: "Bangladesh",
      receiverWarehouse: null,
      selectedDeliveryMethod: null,
      selectedWarehouse: null,
      selectedHomeDeliveryAddress: null,
      selectedCourier: null,
    },
    orderSummery: {
      total_price: 0,
      sales_tax: 0,
      service_charge: 0
    }
  },
};
