import { getHeader } from "@/utils/headers";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define a service using a base URL and expected endpoints
export const shipForMeApi = createApi({
  reducerPath: "shipForMeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_BACKEND_API_URL,
    prepareHeaders: (headers, { getState }) => {
      return getHeader(headers, getState);
    },
  }),
  endpoints: (builder) => ({
    getCountryListWarehouse: builder.query({
      query: () => "shopping/get-country-list-warehouse",
    }),

    createShipForMeOrder: builder.mutation({
      query: (body) => {
        return {
          url: "shopping/create-ship-for-me-order",
          method: "post",
          body: body,
        };
      },
    }),
    editShipForMeOrder: builder.query({
      query: ({ tracking_number }) => {
        return {
          url: `shopping/edit-ship-for-me-order-address/${tracking_number}`,
          method: "get",
          //   body: body,
        };
      },
    }),
    updateShipForMeOrder: builder.mutation({
      query: (body) => {
        return {
          url: `shopping/update-ship-for-me-order`,
          method: "post",
          body: body,
        };
      },
    }),
    updateShipForAddress: builder.mutation({
      query: (body) => {
        return {
          url: `shopping/update-buy-for-me-order-address`,
          method: "post",
          body: body,
        };
      },
    }),
  }),
});
export const {
  useGetCountryListWarehouseQuery,
  useCreateShipForMeOrderMutation,
  useEditShipForMeOrderQuery,
  useUpdateShipForMeOrderMutation,
  useUpdateShipForAddressMutation,
} = shipForMeApi;
