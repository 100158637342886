import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "./ProfileInterface";
export const ProfileSlice = createSlice({
  name: "ProfileSlice",
  initialState,
  reducers: {
    profileToggleSidebar: (state) => {
      state.profile_sidebar = !state.profile_sidebar;
    }
  },
});

export const { profileToggleSidebar } = ProfileSlice.actions;

export default ProfileSlice.reducer;
