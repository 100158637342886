import { PayloadAction, createSlice } from "@reduxjs/toolkit";
interface SliceState {
  sidebar: boolean;
  mobileToggle: boolean;
  customer_address: [];
  address_modal: boolean;
  shippingOrderModal: boolean;
  shoppingOrderModal: boolean;
  shippingOrder: string;
  shoppingOrder: string;
  addFundModal: boolean;
  verifyOptModal: boolean;
  order_type: string;
  editAddress: {
    customer_address_id: string;
    name: string;
    email: string;
    phone_number: string;
    country_code: string;
    zip_code: any;
    city: string;
    address: string;
    address_2: string;
    state: string;
    address_type: string;
  };
}
const initialState: SliceState = {
  sidebar: true,
  mobileToggle: false,
  customer_address: [],
  address_modal: false,
  shippingOrderModal: false,
  shoppingOrderModal: false,
  shippingOrder: "",
  shoppingOrder: "",
  addFundModal: false,
  verifyOptModal: false,
  order_type: "",
  editAddress: {
    customer_address_id: "",
    name: "",
    email: "",
    phone_number: "",
    country_code: "",
    zip_code: "",
    city: "",
    address: "",
    address_2: "",
    state: "",
    address_type: "",
  },
};

export const CommonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.sidebar = !state.sidebar;
    },
    toggleMobileSidebar: (state) => {
      state.mobileToggle = !state.mobileToggle;
      //state.sidebar = true;
    },
    setCustomerAddress: (state, action: PayloadAction<any>) => {
      state.customer_address = action.payload;
    },
    openAddAddressModal: (state, action: PayloadAction<boolean>) => {
      state.address_modal = action.payload;
    },
    setShippingOrderModal: (state, action: PayloadAction<any>) => {
      state.shippingOrderModal = !state.shippingOrderModal;
      state.shippingOrder = action?.payload;
    },
    setShoppingOrderModal: (state, action: PayloadAction<any>) => {
      state.shoppingOrderModal = !state.shoppingOrderModal;
      state.shoppingOrder = action?.payload;
    },
    editCustomerAddressData: (state, action: PayloadAction<any>) => {
      state.editAddress = action?.payload;
    },
    openAddFundModal: (state, action: PayloadAction<boolean>) => {
      state.addFundModal = action?.payload;
    },
    openVerifyOtpModal: (state, action: PayloadAction<boolean>) => {
      state.verifyOptModal = !action?.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleSidebar,
  toggleMobileSidebar,
  setCustomerAddress,
  openAddAddressModal,
  setShippingOrderModal,
  setShoppingOrderModal,
  editCustomerAddressData,
  openAddFundModal,
  openVerifyOtpModal,
} = CommonSlice.actions;

export default CommonSlice.reducer;
