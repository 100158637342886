import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  IBox,
  ICourier,
  ILocation,
  IPickupRquest,
  IProduct,
  initialState,
} from "./ShippingInterface";

const { v4: uuidv4 } = require("uuid");

export const ShippingSlice = createSlice({
  name: "shipping",
  initialState,
  reducers: {
    updateStepsData: (state, action: PayloadAction<any>) => {
      state.steps = action.payload;
    },
    updateSenderInformation: (state, action: PayloadAction<ILocation>) => {
      state.data.shippingInformation.form.sender = action.payload;
    },
    updateReceiverInformation: (state, action: PayloadAction<ILocation>) => {
      state.data.shippingInformation.form.receiver = action.payload;
    },
    createShippingInformationForm: (state, action: PayloadAction<any>) => {
      state.data.shippingInformation.form = action.payload;
    },
    updateValumetricWeight: (state, action: PayloadAction<any>) => {
      state.data.shippingInformation.volumetric_weight = action.payload;
    },
    searchCourerList: (state, action: PayloadAction<any>) => {
      state.data.shippingInformation.courierList = action.payload;
    },
    updateShippingMethod: (state, action: PayloadAction<any>) => {
      state.data.shippingMethod = action.payload;
    },
    createBox: (state, action: PayloadAction<any>) => {
      state.data.boxInformation = action.payload;
    },
    addNewItemToBox: (state, action: PayloadAction<IBox | undefined>) => {
      let findCurrentBox = state.data.boxInformation.find(
        (item: IBox) => item.box_id === action.payload?.box_id
      );
      if (findCurrentBox) {
        findCurrentBox.items?.push({
          id: uuidv4(),
          name: "",
          quantity: "",
          unit_price: "",
        });
      }
    },
    removeItemFromBox: (state, action: PayloadAction<any>) => {
      console.log("request id", JSON.parse(JSON.stringify(action.payload)));

      let findCurrentBox = state.data.boxInformation.find(
        (item: IBox) => item.box_id == action.payload?.box_id
      );

      console.log(
        "current box",
        JSON.parse(JSON.stringify(findCurrentBox?.items))
      );

      if (findCurrentBox) {
        findCurrentBox.items = findCurrentBox?.items?.filter(
          (item: IProduct) => item?.id !== action.payload.item_id
        );
      }
    },
    updateItemToBox: (state, action: PayloadAction<any>) => {
      console.log("action");
      console.log(action.payload);
      let findCurrentBox: any = state.data.boxInformation.find(
        (item: IBox) => item.box_id == action.payload?.box_id
      );

      if (findCurrentBox) {
        findCurrentBox.status = "complete";
        let productData: IProduct[] = [];
        action.payload.items?.map((item: IProduct) => {
          productData.push({
            id: item.id,
            name: item.name,
            quantity: item.quantity,
            unit_price: item.unit_price,
          });
        });
        findCurrentBox.width = action.payload.width;
        findCurrentBox.height = action.payload.height;
        findCurrentBox.length = action.payload.length;
        findCurrentBox.weight = action.payload.weight;
        findCurrentBox.items = action.payload.items;

        let findNextBox: any = state.data.boxInformation.find(
          (item: IBox) => item.box_id === findCurrentBox.box_id + 1
        );
        if (findNextBox) {
          findNextBox.status = "progress";
        }
      }
    },

    editBox: (state, action: PayloadAction<any>) => {
      console.log(JSON.parse(JSON.stringify(state.data.boxInformation)));
      const {
        data: { boxInformation },
      } = state;
      const { payload } = action;

      const currentIndex = boxInformation.findIndex(
        (box) => box.box_id === payload
      );
      if (currentIndex >= 0) {
        boxInformation[currentIndex].status = "progress";

        // Set all other boxes to 'complete' if they were previously 'complete'
        boxInformation.forEach((box, index) => {
          if (index !== currentIndex && box.status === "complete") {
            box.status = "complete";
          } else if (index !== currentIndex) {
            box.status = "pending";
          }
        });
      }
    },

    nextStep: (state) => {
      const currentStepIndex = state.steps.findIndex(
        (step) => step.status === false
      );
      console.log("current Step", currentStepIndex);

      if (currentStepIndex !== -1) {
        const updatedSteps = [...state.steps];
        updatedSteps[currentStepIndex].status = true;
        if (currentStepIndex < updatedSteps.length - 1) {
          updatedSteps[currentStepIndex + 1].status = false;
        }
        state.steps = updatedSteps;
      }
    },
    clearShippingState: (state) => {
      state = initialState;
      return state;
    },
    editShipment: (state) => {
      state.steps = initialState.steps;
    },
    updatePickupRequest: (state, action: PayloadAction<any>) => {
      state.data.pickupRequest = action.payload;
    },
    updatePickupShippingLabel: (state, action: PayloadAction<any>) => {
      state.data.pickupRequest.pickup_courier_label =
        action.payload?.label_data;
      state.data.pickupRequest.pickup_cost = action.payload.pickup_cost;
    },
    updatePaymentType: (state, action: PayloadAction<any>) => {
      state.data.paymentType = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateStepsData,
  nextStep,
  updateSenderInformation,
  updateReceiverInformation,
  searchCourerList,
  updateValumetricWeight,
  updateShippingMethod,
  createBox,
  createShippingInformationForm,
  clearShippingState,
  addNewItemToBox,
  removeItemFromBox,
  updateItemToBox,
  editBox,
  editShipment,
  updatePickupRequest,
  updatePickupShippingLabel,
  updatePaymentType,
} = ShippingSlice.actions;

export default ShippingSlice.reducer;
