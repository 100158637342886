import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { initialState, BuyProductsLinks, } from './ShoppingInterface'

export const ShoppingSlice = createSlice({
    name: "shopping",
    initialState,
    reducers: {
        createProductLink: (state, action: PayloadAction<any>) => {
            let productLinks: Array<BuyProductsLinks> = [];

            for (let index = 0; index < action.payload?.products.length; index++) {
                productLinks.push({
                    link_id: index,
                    link: action.payload?.products?.[index]?.link,
                    description: action.payload?.products?.[index]?.description,
                    images: action.payload?.products?.[index]?.image ?? [],
                    title: action.payload?.products?.[index]?.title,
                    price: action.payload.products?.[index]?.price,
                    status: index === 0 ? "processing" : "pending",
                });
            }
            state.data.buyProductsLinksInfo = productLinks;
            console.log(state.data.buyProductsLinksInfo);
        },
        updateProductInformation: (state, action: PayloadAction<any>) => {
            let currentData = [...state.data.buyProductsLinksInfo];

            for (let index = 0; index < currentData?.length; index++) {
                let element = currentData[index];

                if (element?.status === 'processing') {
                    // Update the current step to 'completed'
                    currentData[index] = {
                        images: [...element.images],
                        link_id: element.link_id,
                        ...action.payload,
                        status: 'completed'
                    };

                    console.log('current length', currentData.length, index + 1);

                    if (index + 1 < currentData.length) {
                        // If there is a next step, mark it as 'processing'
                        currentData[index + 1] = {
                            ...currentData[index + 1],
                            status: 'processing'
                        };
                    }


                    break;
                }
            }
            console.log(currentData);

            state.data.buyProductsLinksInfo = currentData;
        },
        updateProductImage: (state, action: PayloadAction<any>) => {
            let currentData = [...state.data.buyProductsLinksInfo]
            for (let index = 0; index < currentData?.length; index++) {
                const element = currentData[index];
                if (element?.status === 'processing') {
                    element.images.push(action?.payload)
                }
            }
            state.data.buyProductsLinksInfo = currentData
        },
        deleteImage: (state, action: PayloadAction<any>) => {
            let currentData = [...state.data.buyProductsLinksInfo]
            for (let index = 0; index < currentData?.length; index++) {
                const element = currentData[index];
                if (element?.status === 'processing') {
                    let filterImage = element.images.filter((item: any) => item?.image_id != action.payload)

                    element.images = filterImage
                }
            }
            state.data.buyProductsLinksInfo = currentData
        },
        clearShoppingState: (state) => {
            state = initialState
            return initialState
        },
        openCurrentShoppingTab: (state, action: PayloadAction<any>) => {
            let currentData = [...state.data.buyProductsLinksInfo]
            for (let index = 0; index < currentData?.length; index++) {
                let element = currentData[index];
                if (element?.link_id === action.payload) {
                    currentData[index] = {
                        ...element,
                        status: "processing",

                    }
                } else {
                    currentData[index] = {
                        ...element,
                    }
                }
            }
            state.data.buyProductsLinksInfo = currentData
        },
        deleteProduct: (state, action: PayloadAction<any>) => {

        },
        updateDeliveryInformation: (state, action: PayloadAction<any>) => {
            state.data.deliveryInformation = action.payload
        },

    },
});

// Action creators are generated for each case reducer function
export const {
    createProductLink,
    updateProductInformation,
    updateProductImage,
    clearShoppingState,
    deleteImage,
    openCurrentShoppingTab,
    updateDeliveryInformation
} = ShoppingSlice.actions;

export default ShoppingSlice.reducer;
