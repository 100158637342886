import { getHeader } from "@/utils/headers";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define a service using a base URL and expected endpoints
export const editShippingApi = createApi({
  reducerPath: "editShippingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_BACKEND_API_URL,
    prepareHeaders: (headers, { getState }) => {
      return getHeader(headers, getState);
    },
  }),
  endpoints: (builder) => ({
    getShippingInfo: builder.query({
      query: (tracking) => `shipping/order-edit/${tracking}`,
    }),
    updateShippingInfo: builder.mutation({
      query: (body) => {
        return {
          url: "shipping/order/box-update",
          method: "post",
          body: body,
        };
      },
    }),
    updateShippingAddress: builder.mutation({
      query: (body) => {
        return {
          url: "customer/order/address-update",
          method: "post",
          body: body,
        };
      },
    }),
  }),
});

export const {
  useUpdateShippingInfoMutation,
  useGetShippingInfoQuery,
  useUpdateShippingAddressMutation,
} = editShippingApi;
