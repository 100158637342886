import { getHeader } from "@/utils/headers";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


// Define a service using a base URL and expected endpoints
export const invoiceApi = createApi({
    reducerPath: "invoiceApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.NEXT_PUBLIC_BACKEND_API_URL,
        prepareHeaders: (headers, { getState }) => { return getHeader(headers, getState) },
    }),
    endpoints: (builder) => ({
        //get Invoice as html
        getInvoiceHtml: builder.query({
            query: (invoice_id) => {
                return {
                    url: `inv?invoice_id=${invoice_id}`,
                    method: "get",
                };
            },
        }),
        
    }),
});


export const {
  useGetInvoiceHtmlQuery
} = invoiceApi;
