import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { initialState } from './ShipForMeInterface';
const { v4: uuidv4 } = require('uuid');
export const ShipForMeSlice = createSlice({
    name: 'shipforme',
    initialState,
    reducers: {
        createProductItem: (state, action: PayloadAction<any>) => {
            state.data.shipformeinfo.products.push({
                id: uuidv4(),
                item_name: action.payload?.item_name,
                quantity: action?.payload?.quantity,
                weight: action?.payload?.weight,
                weight_type: action?.payload?.weight_type,
            });
        },
        removeProductItem: (state, action: PayloadAction<any>) => {
            let currentData = [...state.data.shipformeinfo.products];
            const filterData = currentData.filter((item) => {
                return item.id !== action.payload
            });
            state.data.shipformeinfo.products = filterData;
        },
        selecteWarehouse: (state, action: PayloadAction<any>) => {
            state.data.selectedWarehouse = action.payload
        }

    }
});

export const {
    createProductItem,
    removeProductItem,
    selecteWarehouse
} = ShipForMeSlice.actions;
export default ShipForMeSlice.reducer;