import { getHeader } from "@/utils/headers";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export const paymentApi = createApi({
    reducerPath: "paymentApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.NEXT_PUBLIC_BACKEND_API_URL,
        prepareHeaders: (headers, { getState }) => { return getHeader(headers, getState) },
    }),
    endpoints: (builder) => ({
        getPaymentList: builder.mutation({
            query: (body) => {
                return {
                    url: "shipping/payment-list",
                    method: "post",
                    body: body,
                };
            },
        }),
        getBillingPayments: builder.query({
            query: () => {
                return {
                    url: "shipping/billing-payments",
                    method: "post",
                };
            },
        }),
        getInvoice: builder.query({
            query: (invoiceId) => {
                return {
                    url: `invoice/details/${invoiceId}`,
                };
            },
        }),
    })
});

export const {
    useGetPaymentListMutation,
    useGetBillingPaymentsQuery,
    useGetInvoiceQuery
} = paymentApi;
