import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
const initialState = {
    invoiceId: ''
}

export const PaymentSlice = createSlice({
    name: "payment",
    initialState,
    reducers: {
        collectInvoiceId: (state, action: PayloadAction<any>) => {
            state.invoiceId = action.payload;
        }

    },
});

// Action creators are generated for each case reducer function
export const {
    collectInvoiceId
} = PaymentSlice.actions;

export default PaymentSlice.reducer;
