import { getHeader } from "@/utils/headers";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import build from "next/dist/build";

// Define a service using a base URL and expected endpoints
export const commonAPi = createApi({
  reducerPath: "commonAPi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_BACKEND_API_URL,
    prepareHeaders: (headers, { getState }) => {
      return getHeader(headers, getState);
    },
  }),
  tagTypes: ["GET_CUSTOMER_ADDRESS"],
  endpoints: (builder) => ({
    getCustomerAddress: builder.query({
      query: (body) => {
        return {
          url: "shopping/get-customer-address",
          method: "post",
          body: body,
        };
      },
      providesTags: ["GET_CUSTOMER_ADDRESS"],
    }),
    createCustomerAddress: builder.mutation({
      query: (body) => {
        return {
          url: "/customer/address/create",
          method: "post",
          body: body,
        };
      },
      invalidatesTags: ["GET_CUSTOMER_ADDRESS"],
    }),
    setDefaultCustomerAddress: builder.mutation({
      query: (body) => {
        return {
          url: "/customer/address/set-default",
          method: "post",
          body: body,
        };
      },
      invalidatesTags: ["GET_CUSTOMER_ADDRESS"],
    }),
    editCustomerAddress: builder.mutation({
      query: (body) => {
        return {
          url: "/customer/address/edit",
          method: "post",
          body: body,
        };
      },
    }),
    updateCustomerAddress: builder.mutation({
      query: (body) => {
        return {
          url: "/customer/address/update",
          method: "post",
          body: body,
        };
      },
      invalidatesTags: ["GET_CUSTOMER_ADDRESS"],
    }),
    deleteCustomerAddress: builder.mutation({
      query: (body) => {
        return {
          url: "/customer/address/delete",
          method: "post",
          body: body,
        };
      },
      invalidatesTags: ["GET_CUSTOMER_ADDRESS"],
    }),
    getDashboardSummary: builder.query({
      query: () => {
        return {
          url: "/customer/dashboard",
          method: "post",
        };
      },
    }),
    getOngoingOrders: builder.query({
      query: () => {
        return {
          url: "/customer/ongoing-orders",
          method: "post",
        };
      },
    }),
    addCredit: builder.mutation({
      query: (body) => {
        return {
          url: "/customer/add-credit",
          method: "post",
          body: body,
        };
      },
    }),
    addCreditViaBank: builder.mutation({
      query: (body) => {
        return {
          url: "/customer/bank-transfer",
          method: "post",
          body: body,
        };
      },
    }),
  }),
});

export const {
  useGetCustomerAddressQuery,
  useCreateCustomerAddressMutation,
  useSetDefaultCustomerAddressMutation,
  useEditCustomerAddressMutation,
  useUpdateCustomerAddressMutation,
  useDeleteCustomerAddressMutation,
  useGetDashboardSummaryQuery,
  useGetOngoingOrdersQuery,
  useAddCreditMutation,
  useAddCreditViaBankMutation,
} = commonAPi;
