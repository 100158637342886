import { getHeader } from "@/utils/headers";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define a service using a base URL and expected endpoints
export const editShopForMeApi = createApi({
  reducerPath: "editShopForMeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_BACKEND_API_URL,
    prepareHeaders: (headers, { getState }) => {
      return getHeader(headers, getState);
    },
  }),
  endpoints: (builder) => ({
    updateEditBuyForMe: builder.mutation({
      query: (body) => {
        return {
          url: "/shopping/update-buy-for-me-order",
          method: "post",
          body: body,
        };
      },
    }),
  }),
});

export const { useUpdateEditBuyForMeMutation } = editShopForMeApi;
