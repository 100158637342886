interface ShipForMeInfo {
  delivery_option: "woow_cargo" | "express";
  delivery_addr_type: string;
  delivery_address: string;
  save_shipper: boolean;
  parcel_update_option: "by_ots" | "by_tracking";
  products: Products[];
  total_price_charge: any;
  ots_number: string;
}
interface Warehouse {
  warehouse_id: number;
  warehouse_name: string;
  manager_name: string;
  phone_number: string;
  address: string;
  state: string;
  city: string;
  zip_code: string;
  is_cargo_enabled: boolean;
}

interface Country {
  country_id: number | null;
  country_name: string | null;
  country_code: string | null;
  warehouse: Warehouse[];
}

interface Products {
  id: string;
  item_name: string;
  quantity: number;
  weight: string;
  weight_type: "kg" | "lbs";
}
interface Address {
  first_name: string;
  last_name: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  country: string;
  zip_code: string;
  phone_number: string;
}
export interface IShipForMe {
  data: {
    shipformeinfo: ShipForMeInfo;
    selectedWarehouse: Warehouse;
  };
}
export const initialState: IShipForMe = {
  data: {
    shipformeinfo: {
      delivery_option: "woow_cargo",
      delivery_addr_type: "",
      delivery_address: "",
      save_shipper: false,
      parcel_update_option: "by_ots",
      total_price_charge: 0,
      ots_number: "",
      products: [],
    },
    selectedWarehouse: {
      warehouse_id: 2,
      warehouse_name: "",
      manager_name: "",
      phone_number: "",
      address: "",
      state: "",
      city: "",
      zip_code: "",
      is_cargo_enabled: false,
    },
  },
};
