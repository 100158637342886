import { configureStore, combineReducers } from "@reduxjs/toolkit";

import ShippingReducer from "@/features/shipping/Shipping.slice";
import ShoppingReducer from "@/features/shopping/Shopping.slice";
import CommonReducer from "@/features/common/Common.slice";
import authReducer from "@/features/login/login.slice";
import { shippingApi } from "@/features/shipping/shipping.api";
import { loginApi } from "@/features/login/login.api";
import { shipForMeApi } from "@/features/shopping/ShipForMe/ShipForMe.api";
import { paymentApi } from "@/features/bill-payment/payment.api";
import ProfileReducer from "@/features/dashboard/profile/Profile.slice";
import ShipForMeReducer from "@/features/shopping/ShipForMe/ShipForMe.slice";
import PaymentReducer from "@/features/bill-payment/Payment.slice";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { persistReducer, persistStore } from "redux-persist";

import { ordersApi } from "@/features/my-orders/orders.api";
import { commonAPi } from "@/features/common/Common.api";
import { shipApi } from "@/components/ShippingRateCalculator/ship.api";
import { editShippingApi } from "@/features/shipping/editShippingApi.slice";
import { editShopForMeApi } from "@/features/shopping/BuyForMe/edit/editShopForMeApi";
import { invoiceApi } from "@/features/my-orders/shopping-orders/shopping/invoiceApi";

const reducers = combineReducers({
  shipping: ShippingReducer,
  shopping: ShoppingReducer,
  common: CommonReducer,
  profile: ProfileReducer,
  auth: authReducer,
  shipforme: ShipForMeReducer,
  payment: PaymentReducer,
  [shippingApi.reducerPath]: shippingApi.reducer,
  [loginApi.reducerPath]: loginApi.reducer,
  [ordersApi.reducerPath]: ordersApi.reducer,
  [commonAPi.reducerPath]: commonAPi.reducer,
  [shipForMeApi.reducerPath]: shipForMeApi.reducer,
  [paymentApi.reducerPath]: paymentApi.reducer,
  [shipApi.reducerPath]: shipApi.reducer,
  [editShippingApi.reducerPath]: editShippingApi.reducer,
  [editShopForMeApi.reducerPath]: editShopForMeApi.reducer,
  [invoiceApi.reducerPath]: invoiceApi.reducer,
});
const persistConfig = {
  key: "_w_id",
  storage,
  whitelist: ["auth", "shipping", "shopping"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(shippingApi.middleware)
      .concat(loginApi.middleware)
      .concat(ordersApi.middleware)
      .concat(commonAPi.middleware)
      .concat(shipForMeApi.middleware)
      .concat(paymentApi.middleware)
      .concat(shipApi.middleware)
      .concat(editShippingApi.middleware)
      .concat(editShopForMeApi.middleware)
      .concat(invoiceApi.middleware),
  devTools: process.env.NODE_ENV !== "production",
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
