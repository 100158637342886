import { getHeader } from "@/utils/headers";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define a service using a base URL and expected endpoints
export const shippingApi = createApi({
  reducerPath: "shippingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_BACKEND_API_URL,
    prepareHeaders: (headers, { getState }) => { return getHeader(headers, getState) },
  }),
  endpoints: (builder) => ({
    getZipCode: builder.mutation({
      query: (body) => {
        return {
          url: "customer/location/zip-code",
          method: "post",
          body: body,
        };
      },
    }),
    getProductType: builder.query({
      query: () => 'shipping/product-category/list',
    }),
    getBoxList: builder.query({
      query: () => {
        return {
          url: "shipping/box-dimension/list",
          method: "post",
        };
      },
    }),
    getPrice: builder.mutation({
      query: (body) => {
        return {
          url: "shipping/courier/check-price",
          method: "post",
          body: body,
        };
      },
    }),
    createCourierOrder: builder.mutation({
      query: (body) => {
        return {
          url: "/shipping/courier/create-order",
          method: "post",
          body: body,
        };
      },
    }),
    getPickupInfo: builder.mutation({
      query: (body) => {
        return {
          url: "/pickup/check-available-dates",
          method: "post",
          body: body,
        };
      },
    }),
    createPayment: builder.mutation({
      query: (body) => {
        return {
          url: "/invoice/make-payment",
          method: "post",
          body: body,
        };
      },
    }),
    makeStripePayment: builder.mutation({
      query: (body) => {
        return {
          url: "invoice/make-payment/usd",
          method: "post",
          body: body,
        }
      }
    }),
    validateAddress: builder.mutation({
      query: (body) => {
        return {
          url: "/shipping/validate-address",
          method: "post",
          body: body,
        }
      }
    }),
  }),
});


export const {
  useGetZipCodeMutation,
  useGetBoxListQuery,
  useGetProductTypeQuery,
  useGetPriceMutation,
  useCreateCourierOrderMutation,
  useGetPickupInfoMutation,
  useCreatePaymentMutation,
  useMakeStripePaymentMutation,
  useValidateAddressMutation
} = shippingApi;
