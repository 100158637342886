export interface ISteps {
  id: number;
  title: string;
  status: boolean | null;
}
export interface ILocation {
  city: string;
  country_code: string;
  country_id: number;
  postal_code: string;
  state: {
    short_name: string;
    full_name: string;
  };
  full_address: string;
  country: string;
  address_1?: string;
  address_2?: string;
  name?: string;
  phone_number?: string;
  status?: boolean;
  email?: string;
}

export interface IShippingForm {
  sender_zip_code: string;
  receiver_zip_code: string;
  weight: string;
  weight_unit: string;
  product_type: string | null;
  box_quantity: number;
  box_dimension_type: string | null;
  box_dimension: {
    box_length: string;
    box_width: string;
    box_height: string;
  };
  box_information: any[];
  insurance: any;
  product_value: any;
  premium_value: any;
  delivery_type: any;
  sender?: any;
  receiver?: any;
}
export interface IPickupRquest {
  service_type?: string;
  carrier?: string;
  pickup_date?: string;
  ready_time?: string;
  latest_time?: string;
  status?: boolean;
  pickup_cost?: number;
  pickup_courier_label?: ICourier;
}
export interface IShippingInformation {
  senderInformation: ILocation;
  receiverInformation: ILocation;
  form: IShippingForm;
  volumetric_weight: number;
  courierList: any;
}

export interface IShipping {
  steps: Array<ISteps>;
  data: {
    shippingInformation: IShippingInformation;
    shippingMethod: ICourier;
    boxInformation: IBox[];
    pickupRequest: IPickupRquest;
    paymentType: "FULL_PAYMENT" | "PICKUP_CHARGE_ONLY";
  };
}

export interface ICourier {
  courier_id: string;
  courier_name: string;
  delivery_time: number;
  delivery_time_text: string;
  description: string;
  import_tax: string;
  logo: string;
  shipping_charge: number;
  provider?: string;
  service_type?: string;
  pickup_partner: "WOOW_ESC" | "WOOW_FDX" | "LOCAL_USA" | null;
  pickup_charge?: number | null;
}

export interface IProduct {
  id: Number | string;
  item_id?: string; //for edit shipment
  name: string;
  quantity: string;
  unit_price?: string;
  price?: string; //for edit shipment
}

export interface IBox {
  box_id: number;
  status: "complete" | "pending" | "progress";
  name: string;
  length: string;
  width: string;
  height: string;
  weight: string;
  items?: IProduct[];
}

export const steps: ISteps[] = [
  {
    id: 1,
    title: "Shipping Information",
    status: false,
  },
  {
    id: 2,
    title: "Shipping Methods",
    status: null,
  },
  {
    id: 3,
    title: "Contact Information",
    status: null,
  },
  {
    id: 4,
    title: "Preview Shipment",
    status: null,
  },
  {
    id: 5,
    title: "Enter Payments",
    status: null,
  },
];
export const initialState: IShipping = {
  steps: steps,
  data: {
    shippingInformation: {
      senderInformation: {
        city: "",
        country_code: "US",
        country_id: 234,
        postal_code: "",
        state: {
          short_name: "",
          full_name: "",
        },
        full_address: "",
        country: "",
        name: "",
        status: false,
      },
      receiverInformation: {
        city: "",
        country_code: "US",
        country_id: 234,
        postal_code: "",
        state: {
          short_name: "",
          full_name: "",
        },
        full_address: "",
        country: "",
        status: false,
      },
      form: {
        box_dimension: {
          box_height: "",
          box_length: "",
          box_width: "",
        },
        box_dimension_type: null,
        box_quantity: 1,
        product_type: null,
        receiver_zip_code: "",
        sender_zip_code: "",
        weight: "",
        weight_unit: "lb",
        box_information: [],
        insurance: "",
        product_value: 0,
        premium_value: 0,
        delivery_type: "",
      },

      volumetric_weight: 0,
      courierList: {},
    },
    shippingMethod: {
      courier_id: "",
      courier_name: "",
      delivery_time: 0,
      delivery_time_text: "",
      description: "",
      import_tax: "",
      logo: "",
      shipping_charge: 0,
      pickup_partner: null,
    },
    boxInformation: [
      {
        box_id: 1,
        name: "Box 1",
        status: "progress",
        height: "1",
        length: "1",
        weight: "1",
        width: "1",
        items: [
          {
            id: 1,
            name: "",
            quantity: "",
            unit_price: "",
          },
        ],
      },
    ],
    pickupRequest: {
      status: false,
      pickup_cost: 0,
    },
    paymentType: "FULL_PAYMENT",
  },
};
