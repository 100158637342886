import { ILocation } from "../shipping/ShippingInterface";

export interface IUser {
  email: string | null;
  name: string | null;
  phone_number: string | null;
  reg_date: string | null;
  required_information: string | null;
  social_type: string | null;
  token: string | null;
  user_id: string | null;
  user_key: string | null;
  verification_method?: {
    email: false,
    phone_number: false,
    google: false,
    facebook: false
  }
}
export interface IOtpVerification {
  country_code: string;
  phone_number: string;
}
export interface IUserAddress {
  id: number;
  user_id: number;
  name: string;
  email: string;
  phone_number: string;
  address: string;
  address_2: string | null;
  full_address?: string,
  state: string;
  city: string;
  address_type: string;
  country_id: number;
  country_code: string;
  country_name?: string,
  zip_code: number;
  is_default: boolean | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

interface IInitialState {
  user: IUser;
  token: string;
  otpVerification: IOtpVerification;
  default_address: ILocation[];
}

export const initialState: IInitialState = {
  user: {
    email: null,
    name: null,
    phone_number: null,
    reg_date: null,
    required_information: null,
    social_type: null,
    token: null,
    user_id: null,
    user_key: null,
  },
  token: "",
  otpVerification: {
    country_code: "",
    phone_number: "",
  },
  default_address: [
    {
      city: "",
      country_code: "",
      country_id: 0,
      postal_code: "",
      state: {
        short_name: "",
        full_name: "",
      },
      full_address: "",
      country: "",
    }
  ]
};

export type LOGIN_INPUT = {
  auth_type: "OTP_LOGIN" | "EMAIL_LOGIN" | "SOCIAL_LOGIN" | "VERIFY_OTP",
  email: string,
  password: string,
  otp_code: string,
  phone_number: string,
  country_code: string,
  dial_code: string
};

export type LoginInfo = {
  email: string,
  password: string
}




