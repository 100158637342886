import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { initialState } from "./LoginInterface";
import { Cookies, setCookie } from "typescript-cookie";
import { ILocation } from "../shipping/ShippingInterface";

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<any>) => {
      console.log(action.payload);
      state.user = action.payload;
      let addresses: Array<ILocation> = [];
      if (action?.payload?.customer_addresses) {
        for (
          let index = 0;
          index < action.payload.customer_addresses.length;
          index++
        ) {
          addresses.push({
            city: "",
            country_code:
              action.payload?.customer_addresses[index]?.country_code,
            country_id: action.payload?.customer_addresses[index]?.country_id,
            postal_code: action.payload?.customer_addresses[index]?.zip_code,
            state: {
              short_name: "",
              full_name: "",
            },
            full_address: action.payload?.customer_addresses[index]?.address,
            country: "",
            name: action.payload?.customer_addresses[index]?.name,
            status: false,
          });
        }
      }

      state.default_address = addresses;
      state.token = action?.payload?.token;
      setCookie("_wid", action?.payload?.token);
    },
    handleOtpLogin: (state, action: PayloadAction<any>) => {
      state.otpVerification = action.payload;
    },
    handleLogout: (state) => {
      Cookies.remove("_wid");
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { login, handleOtpLogin, handleLogout } = authSlice.actions;

export default authSlice.reducer;
